import request from '../request'

export function fetchList(params) {
    return request({
        url: '/common-service/officialadmin/job/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/common-service/officialadmin/job/save',
        method: 'POST',
        data
    })
}

export function fetchDetail(params) {
    return request({
        url: '/common-service/officialadmin/job/query',
        params,
    })
}

export function edit(data) {
    return request({
        url: '/common-service/officialadmin/job/modify',
        method: 'POST',
        data
    })
}

export function remove(data) {
    return request({
        url: '/common-service/officialadmin/job/delete',
        method: 'POST',
        data
    })
}