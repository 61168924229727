var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"岗位名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'name',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"学历要求"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'degree',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'degree',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"岗位分类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'category',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'category',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.jobCategoryList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"岗位类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'source',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'source',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.jobSourceList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"经验要求"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'experience',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'experience',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"岗位要求","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'postRequire',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'postRequire',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"岗位描述","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'postDescription',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'postDescription',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saveLoading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }